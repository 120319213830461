import '../App.css';

function AboutText() {
    return (
        <div class="time-left-wrapper">
       
Welcome to beautiful Ojai, California.  Home of the Pink Moment.
<br/><br/>
From a young age we all learn that the sun sets to the west, and of course that is also true here in Ojai.  One of the more wonderful things about Ojai is our sunset, which is unique in that it is best seen when looking to the east!
<br/><br/>
Ojai is somewhat unique in that it lies in a rare east/west valley.  Most mountain ranges run on a north/south axis which means that most valleys run north and south.  Our valley catches the late sun rather than blocking it out.
<br/><br/>
As the sun starts to set over the Pacific Ocean, its light travels a long way through the atmosphere and refracts into marvelous colors upon the Topatopa Bluffs, towering over Ojai Valley.  The naturally reddish rocky faces of these bluffs create a perfect canvas for light that is refracted and possibly also reflected by the ocean.  For a few fleeting moments the Topatopa mountains illuminate with glorious shades of pink along with bands of orange and red.
<br/><br/>
While the sun does set daily, the Pink Moment is not always visible due to clouds, precipitation, or other impediments that block the sun's light from reaching our 6,000+ foot mountains.
<br/><br/>

If you have a photo of the Pink Moment that you'd like share, please tag us on Instagram @ojaipinkmoment 
    </div>
  );
}

export default AboutText;
